import { useMemo } from 'react';
import { IconName, icons } from './icons';
import { IconSize } from '@constants/atom';

type Props = Styleable & {
  readonly size?: IconSize;
  readonly name: IconName;
  readonly fill?: string;
  readonly stroke?: string;
  readonly onClick?: () => void;
};

export default function Icon({ size = IconSize.MEDIUM, style, name, fill = 'transparent', stroke, onClick }: Props) {
  const Component = icons[name] || null;

  // cursor type
  const Pointer = 'pointer';
  const Default = 'default';

  const cursor = useMemo(() => {
    if (!!onClick) {
      return Pointer;
    }

    return Default;
  }, [onClick]);

  const width = size;
  const height = size;

  return <Component style={{ width, height, fill, stroke, cursor: cursor, ...style }} onClick={onClick} />;
}
