import API from '../axios';

// type
import {
  AuthPostAdminLoginData,
  AuthPostAdminLoginError,
  AuthPostAdminLoginPayload,
  AuthPostAdminRefreshData,
  AuthPostAdminRefreshError,
  AuthPostAdminRefreshPayload,
  UserGetAdminUserProfileData,
  UserGetAdminUserProfileError,
} from 'models/swagger/data-contracts';
import { AxiosResponse } from 'axios';

/**
 *  로그인 API
 *  @function loginApi
 *  @param {string} email 이메일
 *  @param {string} password 비밀번호
 */
export const loginApi = ({
  email,
  password,
}: AuthPostAdminLoginPayload): Promise<AxiosResponse<AuthPostAdminLoginData, AuthPostAdminLoginError>> => {
  return API.post('/auth/login', {
    email,
    password,
  });
};

/**
 *  새 인증 토큰 요청 API
 *  @function getNewAuthTokenApi
 *  @param {string} refresh_token 리프레쉬 토큰
 */
export const getNewAuthTokenApi = ({
  refresh_token: refreshToken,
}: AuthPostAdminRefreshPayload): Promise<AxiosResponse<AuthPostAdminRefreshData, AuthPostAdminRefreshError>> => {
  return API.post('/auth/refresh', {
    refresh_token: refreshToken,
  });
};

/**
 *  로그인 유저 정보 조회 API
 *  @function getLoginUserProfileApi
 */
export const getLoginUserProfileApi = (): Promise<
  AxiosResponse<UserGetAdminUserProfileData, UserGetAdminUserProfileError>
> => {
  return API.get('/user/profile');
};
