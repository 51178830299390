import { Toast } from '@components/atoms/Toast';
import { ApiResponseType, ErrorMessageCode } from '@constants/api';
import { LOGOUT } from '@constants/routes/routes';
import { MINUTE, MONTH, SECOND } from '@constants/time';
import { ACCESS_TOKEN, getCookie, REFRESH_TOKEN, removeCookie, setCookie } from '@utils/cookie';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { getNewAuthTokenApi } from './auth';

const baseURL = process.env.NEXT_PUBLIC_API_URL;
const currentDomain = process.env.NEXT_PUBLIC_DOMAIN;

const api = axios.create({
  baseURL: baseURL,
  timeout: 3 * 1 * 1000, // ms 단위
  headers: {
    'Content-Type': 'application/json',
  },
});

/* request */
api.interceptors.request.use(
  async function (config: AxiosRequestConfig) {
    const accessToken = getCookie(ACCESS_TOKEN);
    const Authorization = `Bearer ${accessToken}`;

    if (accessToken) {
      config.headers!.Authorization = Authorization;
    }

    return config;
  },

  function (error) {
    return Promise.reject(error);
  },
);

let retryCount = 0;
const maximumRetryCount = 3;
/* response */
api.interceptors.response.use(
  function (response: AxiosResponse<ApiResponseType>) {
    if (response && response.status === 200) {
      retryCount = 0;
    }

    return response;
  },

  async function (error: AxiosError<ApiResponseType>) {
    const errorResponse = error.response;
    const prevRequest = error.config;

    if (isExpiredAccessToken(errorResponse) || (isAdminAuthRequired(errorResponse) && retryCount < 3)) {
      try {
        retryCount++;

        const refreshToken = getCookie(REFRESH_TOKEN);
        const refreshTokenData = await getNewAuthTokenApi({ refresh_token: refreshToken });

        const newAccessToken = refreshTokenData.data?.data?.access_token;
        const newRefreshToken = refreshTokenData.data?.data?.refresh_token;

        setCookie(ACCESS_TOKEN, newAccessToken, { maxAge: 5 * MINUTE, domain: currentDomain, path: '/' });
        setCookie(REFRESH_TOKEN, newRefreshToken, { maxAge: 3 * MONTH, domain: currentDomain, path: '/' });
        prevRequest.headers!.Authorization = newAccessToken!;

        return api(prevRequest);
      } catch (refreshError) {
        removeCookie(ACCESS_TOKEN, { domain: currentDomain, path: '/' });
        removeCookie(REFRESH_TOKEN, { domain: currentDomain, path: '/' });

        return Promise.reject(error);
      }
    }
    if (retryCount >= maximumRetryCount) {
      location.replace(LOGOUT);
    }

    if (isNotExistUserError(errorResponse)) {
      location.replace(LOGOUT);
    }

    if (isInValidUser(errorResponse)) {
      Toast.error(errorResponse?.data?.message.text);
    }

    if (isTimeoutError(error)) {
      Toast.error('요청시간이 만료되었습니다. 새로고침후 다시 시도해주세요.');
      // 위와 같이 처리하거나, errorBoundary로 error를 넘겨서 timeout이면 ErrorUI에 요청만료됨, 다시시도 해주세요. 와 같이 표시해주면될듯.
    }

    return Promise.reject(error);
  },
);

function isExpiredAccessToken(errorResponse: AxiosResponse<ApiResponseType, any> | undefined) {
  //errorResponse.status 가 400으로 오는 api들이 몇몇있어서 서버단에서 해결되면 errorResponse.status === 401 도 검사하는걸로 할예정입니다.
  if (errorResponse?.data?.mcode === ErrorMessageCode.EXPIRED_TOKEN) {
    return true;
  }
  return false;
}

function isAdminAuthRequired(errorResponse: AxiosResponse<ApiResponseType, any> | undefined) {
  if (errorResponse?.data?.mcode === ErrorMessageCode.ADMIN_AUTH_REQUIRED && errorResponse?.status === 401) {
    return true;
  }
  return false;
}

function isInValidUser(errorResponse: AxiosResponse<ApiResponseType, any> | undefined) {
  if (errorResponse?.data?.mcode === ErrorMessageCode.INVALID_USER && errorResponse?.status === 401) {
    return true;
  }
  return false;
}

function isNotExistUserError(errorResponse: AxiosResponse<ApiResponseType, any> | undefined) {
  if (errorResponse?.data?.mcode === ErrorMessageCode.NOT_EXIST_USER) {
    return true;
  }

  return false;
}

function isTimeoutError(error: AxiosError<ApiResponseType>) {
  if (error.code === 'ECONNABORTED' || error.status === '408') {
    console.log(error.message);
    return true;
  }
  return false;
}

export default api;
